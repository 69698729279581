import React from 'react';

export const PinIconSmall = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6613 5.65112L5.29931 0.289166L4.5334 1.05454L5.29985 1.821V1.82208L2.6186 4.50225L2.61806 4.50333L1.85215 3.73633L1.08623 4.50279L3.38452 6.80054L0.320312 9.86421L1.08623 10.6301L4.15044 7.56646L6.44873 9.86421L7.21465 9.09883L6.44873 8.33238L9.12998 5.65112L9.8959 6.41704L10.6613 5.65112ZM5.68281 7.56592L5.68227 7.56646L3.38452 5.26817L6.06523 2.58692L8.36298 4.88467L5.68281 7.56592Z"
      fill="#858688"
    />
  </svg>
);
