import React from 'react';

export const SideBarInvestment = ({
  setChatIcon,
  setBankIcon,
  setProfileIcon,
  setInvestmentIcon
}) => (
/*   <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => {
      setChatIcon(true); 
      setBankIcon(false);
      console.log('Chat clicked'); 
    }}
  >
    <path
      d="M17.093 16L21.3143 0.291999H17.9263L15.3523 11.116H15.3083L12.6243 0.291999H9.39031L6.6623 10.984H6.6183L4.1323 0.291999H0.678305L4.8363 16H8.3343L10.9523 5.308H10.9963L13.6583 16H17.0903Z"
      fill="var(--primary-color)"
    />

  </svg> */

<svg 
    width="22"
    height="16"
    viewBox="0 0 22 16"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => {
      setChatIcon(false); 
      setBankIcon(false);
      setProfileIcon(false);
      setInvestmentIcon(true);
      console.log('Investment clicked'); 
    }}
>
 
<text x="45%" y="45%" text-anchor="middle" fill="purple" font-size="13px" fontWeight="Bold" font-family="Arial" dy=".4em">IB</text>
Sorry, your browser does not support inline SVG.
</svg>

);
