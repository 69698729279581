import React, { useRef, useEffect, useCallback, useState  } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import ChatIcon from '@material-ui/icons/Chat';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import { useHistory } from "react-router-dom";
import { Feed } from '../Feed/Feed';
const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom:20px;
  z-index:1;
`;
const ModalWrapper = styled.div`
  width: 300px;
  height: 650px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  left:280px;
`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  p {
    margin-bottom: 1rem;
  }
  button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
    display:inline-block;
  }
  width:250px;
  margin-left:20px;
  margin-bottom:300px;
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));

export const Modal = ({ showModal, setShowModal,partner }) => {
  const modalRef = useRef();
  const classes = useStyles();
  const [buttonClicked, setButtonClicked] = useState(false);
  const history = useHistory();
  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`
  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
        console.log('I pressed');
      }
    },
    [setShowModal, showModal]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );
  function handleClick() {
    history.push("/myprofile/profile");
  }
  
  return (
    <>
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <h1>Are you ready?</h1>
                <p>Chat with the seller or feed about seller .</p>
                <div className="row">
                <div className="col-md-10">
                <Button
        variant="contained"
        color="secondary"
        className= "col-md-4"
        startIcon={<ChatIcon />}
        style={{ margin: '5px' }}
        onClick= {(event) =>
            (window.location.href = `/app/${partner}`)
          } 
      >
        Chat
      </Button>
      <Button
        variant="contained"
        color="primary"
        className="col-md-4"
        startIcon={<DynamicFeedIcon/>}
        style={{ margin: '5px' }}
        onClick= {(event) =>
          (window.location.href = `/feeds`)
        } 
      >
        Feed
      </Button>
      </div>
         </div>
              </ModalContent>
              <CloseModalButton
                aria-label='Close modal'
                onClick={() => setShowModal(prev => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};