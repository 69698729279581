import React from 'react';

export const SideBarFlag = ({
    setChatIcon,
    setBankIcon,
    setProfileIcon,
    setInvestmentIcon
}) => (
/*   <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => {
      setBankIcon(true);
      setChatIcon(false)
      console.log('clicked bank icon'); 
    }}
  >
    <path
      d="M12.1848 13.5851C12.3269 13.6514 12.4785 13.684 12.6311 13.684C12.8722 13.684 13.1122 13.6008 13.3059 13.4398L19.6215 8.17672C19.8479 7.98725 19.9858 7.71252 19.9984 7.41674C20.0121 7.12095 19.9005 6.83359 19.691 6.62412L13.3753 0.308435C12.9638 -0.103137 12.2985 -0.103137 11.8869 0.308435L9.4733 2.72208L8.11227 1.36105C7.7007 0.949477 7.03545 0.949477 6.62387 1.36105L0.308191 7.67673C-0.103381 8.0883 -0.103381 8.75356 0.308191 9.16513L12.9396 21.7965L14.428 20.3081L7.80385 13.684L11.5785 9.90933V12.6314C11.5785 13.0398 11.8154 13.4114 12.1848 13.5851Z"
      fill="white"
    />
  </svg> */
  <svg 
    width="22"
    height="16"
    viewBox="0 0 22 16"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => {
      setBankIcon(true);
      setChatIcon(false);
      setProfileIcon(false);
      setInvestmentIcon(false);
      console.log('clicked bank icon'); 
    }}
>
 
  <text x="45%" y="45%" text-anchor="middle" fill="White" font-size="13px" fontWeight="Bold" font-family="Arial" dy=".4em">OB</text>
Sorry, your browser does not support inline SVG.
</svg>
);
