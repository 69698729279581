import React from 'react';

export const LightningBoltSmall = () => (
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.75774 0.666748H5.66683L3.72744 4.54554H5.66683L2.03047 11.3334L2.75774 6.48493H0.333496L2.75774 0.666748Z"
      fill="white"
    />
  </svg>
);
