import React, { useCallback, useState,useEffect } from "react";
import { Fragment } from "react";
import { usePlaidLink } from "react-plaid-link";
import axios from "axios";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { PlaidLink } from "react-plaid-link";
import MaterialTable from "material-table";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Divider from "@material-ui/core/Divider";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";


import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Modal } from "../Modal/Modal";
import { GlobalStyle } from "./globalStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  listgroup: {
    width: "100%",
    maxWidth: 1260,
    backgroundColor: theme.palette.background.paper,
    maxHeight: 600,
    overflow: "auto",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 1100,
  },
  container: {
    maxHeight: 590,
  },

  left: {
    padding: "50px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function AccessTokenLink(props) {
  const {
    itype
  } = props;
  // alert("In Link ");
  const [loading, setLoading] = useState(false);
  const [appState, setAppState] = useState({});
  const [toChat, setChat] = useState(false);
  const classes = useStyles();
  const [list, setList] = useState([]);
  const history = useHistory();
  //const username = user.email.replace(/([^a-z0-9_-]+)/gi, '_');
  //const useremail = user.email;
  const [merchantName, setMerchantName] = useState();
  //alert("username: "+username);
  //https://newapp-97d46.web.app
  var public_token;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [partnerName,setPartner]= useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const openModal = () => {
    setShowModal(prev => !prev);
  };
  const handleClick = () => {};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {    // Update the document title using the browser API    
    console.log(' use effect ');
    setChat(false);
      // send token to server
      console.log('itype is : '+itype);
      let locStorage = localStorage.getItem("data");
      if (itype == 'investment') {
        console.log('in investment'+locStorage);
        
          axios
            .post("https://us-central1-newapp-97d46.cloudfunctions.net/app/investmentswithat",{data: {"accesstoken": locStorage}})
            .then((res) => {
              //alert(JSON.stringify(res.data.transactions.transactions));
              JSON.stringify(res.data);
              console.log(res.data);
              //setData(res.data);
              const jsondata = res.data.investments.investment_transactions;
              console.log('json: '+jsondata);
              const partnername = "uber";
              const lists = (
                <List className={classes.listgroup}>
                  {/* <ListSubheader>{`Tuesday 05 April`}</ListSubheader> */}
                  <Divider />
                  {jsondata.map((trans) => (
                    <>
                      <ListItem
                        /* button
                        onClick={(event) =>
                          (window.location.href = `/app/${partnername}`)
                        } */
                        button
                        onClick={() => {setShowModal(prev => !prev);setPartner(partnername)}}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            {/* <ImageIcon /> */}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={trans.name}
                          secondary={`${trans.date} | Type: ${trans.type} | Currency: ${trans.iso_currency_code} | Quantity: ${trans.quantity} | Price: ${trans.price} `}
                        />
                        <ListItemSecondaryAction className={classes.left}>
                          ${trans.amount}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              );
              setList(lists);
            });
      
    
      } else if (itype=='transactions'){
        console.log('in transactions');
       axios
            .post("https://us-central1-newapp-97d46.cloudfunctions.net/app/transactionswithat",{data: {"accesstoken": locStorage}})
            .then((res) => {
              //alert(JSON.stringify(res.data.transactions.transactions));
              JSON.stringify(res.data);
              console.log(res.data);
              setData(res.data);
              const jsondata = res.data.transactions.transactions;
              const partnername = "uber";
              const lists = (
                <List className={classes.listgroup}>
                  <ListSubheader>{`Tuesday 05 April`}</ListSubheader>
                  <Divider />
                  {jsondata.map((trans) => (
                    <>
                      <ListItem
                        /* button
                        onClick={(event) =>
                          (window.location.href = `/app/${partnername}`)
                        } */
                        button
                        onClick={() => {setShowModal(prev => !prev);setPartner(partnername)}}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            {/* <ImageIcon /> */}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={trans.name}
                          secondary={`${trans.datetime} | ${trans.payment_channel} `}
                        />
                        <ListItemSecondaryAction className={classes.left}>
                          ${trans.amount}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              );
              setList(lists);
            });
      }

    },[]);
      


  var book;

  return (
    <div>


    {!toChat && list}
    <Modal showModal={showModal} setShowModal={setShowModal} partner={partnerName} />
    <GlobalStyle/>
    {/* {toChat && <ChatView name ={useremail} room={'ubers'}/>} */}
  </div>
  );
}

export default AccessTokenLink;
