import React from 'react';
import { useAuth0 } from '../components/Auth0/auth0';

const Profile = () => {
    const { user, logout } = useAuth0();
  return (
    <div className='home'>
      <h1>Logged in email</h1>
      {user.email}
    </div>
  );
};

export default Profile;