import React from "react";

import "./TeamChannelList.css";

import { AddChannel } from "../../assets";

const ChannelList = (props) => {
  const {
    children,
    error = false,
    loading,
    setCreateType,
    setIsCreating,
    setIsEditing,
    type,
  } = props;

  /**
   * This work around removes children of the other channel type since we have
   * two ChannelList components and each receives the `message.new` event,
   * which by default adds the channel in question to children on each list.
   */
  let newChildren = children;
  let childArray;
  //console.log('type is: '+type);
  if (type === "team") {
    childArray = newChildren?.props?.children?.filter(
      (child) => child.props.channel.type === "team"
    );
  }

  if (type === "messaging") {
    childArray = newChildren?.props?.children?.filter(
      (child) => child.props.channel.type === "messaging"
    );
  }

  if (type === "apps") {
    console.log("Apps");
    childArray = newChildren?.props?.children?.filter(
      (child) => child.props.channel.type === "apps"
    );
  }

  newChildren = {
    ...newChildren,
    props: {
      children: childArray,
    },
  };

  if (error) {
    return type === "team" ? (
      <div className="team-channel-list">
        <p className="team-channel-list__message">
          Connection error, please wait a moment and try again.
        </p>
      </div>
    ) : null;
  }

  if (loading) {
    return (
      <div className="team-channel-list">
        <p className="team-channel-list__message loading">
          {type === "team" ? "Channels" : "Messages"} loading....
        </p>
      </div>
    );
  }
  if (type != "apps") {
    console.log('in team channel list');
    return (
      <div className="team-channel-list">
        <div className="team-channel-list__header">
          <p className="team-channel-list__header__title">
            {type === "team" ? "Channels" : "Direct Messages"}
            {type === "apps" ? "Plaid App" : ""}
          </p>
          <AddChannel
            {...{ setCreateType, setIsCreating, setIsEditing }}
            type={type === "team" ? "team" : "messaging"}
          />
        </div>
        {newChildren}
      </div>
    );
  } else {
    return (
      <div className="team-channel-list">
        <div className="team-channel-list__header">
          <p className="team-channel-list__header__title">
            {type === "apps" ? "Plaid App" : "Apps"}
          </p>
          <AddChannel
            {...{ setCreateType, setIsCreating, setIsEditing }}
            type={type === "apps" ? "apps" : "apps"}
          />
        </div>
      </div>
    );
  }
};

export const TeamChannelList = React.memo(ChannelList);
