import React, { useState, useEffect } from "react";
import {
  Chat,
  Channel,
  Thread,
  Window,
  ChannelList,
  ChannelListTeam,
  MessageList,
  MessageTeam,
  MessageInput,
  withChannelContext
} from "stream-chat-react";
import { StreamChat } from "stream-chat";
import { getRandomImage } from "../../assets";
import axios from "axios";
import { useAuth0 } from "../Auth0/auth0";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
  } from "react-router-dom";
import "stream-chat-react/dist/css/index.css";
import { ChatContainer } from "./ChatContainer";


const urlParams = new URLSearchParams(window.location.search);
const apiKey = urlParams.get("apikey") || process.env.REACT_APP_STREAM_KEY;
const client = new StreamChat(apiKey);

function ChatView() {
  const [channel, setChannel] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user, logout } = useAuth0();
const [ userToken, setUserToken ] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [isPartner, setIsPartner] = useState("");
  const [isFeedPage, setIsFeedPage] = useState("");

  // Usernames can only contain alphabets, numbers, underscores and dashes
  const username = user.name;
  let token;
  useEffect(() => {
    async function getToken() {

        console.log('username : '+username);
      setLoading(true);
      
      try {
        const response = await axios.post("https://us-central1-newapp-97d46.cloudfunctions.net/app/createuser", {
          username
        });
        token = response.data.token;
      } catch (err) {
        console.log(err);
        return;
      }

     /*  chatClient.setUser(
        {
          id: username,
          name: user.nickname
        },
        token
      ); */
      try {
          console.log('token is '+token);
          console.log('username is '+username);
         // setUsersToken(token);
        client
          .connectUser({ id: username, name: username, image: getRandomImage() }, token)
          .catch(Error);
          console.log('token is after '+token);
          setUserToken(token);
      } catch (Error) {
        console.log("Error to connect");
      }

      /* const channel = client.channel("team", "group-messaging-2");

      try {
        await channel.watch();
      } catch (err) {
        console.log(err);
        return;
      } */

     // setChannel(channel);
      setLoading(false);
    }

    getToken();
  }, [setLoading, user.email, user.name, user.nickname, username]);

  if (loading || !user) {
    return <div>Loading chat...</div>;
  }

  

    function SetPartnerPage() {
        let { partnername } = useParams();
        console.log("Partner: " + partnername);
        setPartnerName(partnername);
        if (isPartner =='') {
        setIsPartner("yes")
        }
        return "";
      }
      function SetFeedPage() {
        console.log('in feed func'+token);
      //  setUserToken(token);
        setIsFeedPage('yes');
        return "";
      }
    return (
        <>
        <Router>
          <Switch>
            <Route path="/app/:partnername" >
              <SetPartnerPage />
            </Route>
            <Route path="/feeds" >
            <SetFeedPage />
            </Route>
          </Switch>
        </Router>
        <ChatContainer {...{ client,partnerName,setIsPartner,isPartner,setIsFeedPage,isFeedPage,userToken,setUserToken }} />
      </>
    );
    };

 

export default ChatView;