import React from 'react';
import { ChannelList } from 'stream-chat-react';
import { AddChannel } from '../../assets';
import './BankingListContainer.css';

import { ChannelSearch } from '../ChannelSearch/ChannelSearch';
import { TeamChannelList } from '../TeamChannelList/TeamChannelList';
import { TeamChannelPreview } from '../TeamChannelPreview/TeamChannelPreview';
import { SidebarData } from '../SidebarMenu/SidebarData';
import SubMenu from '../SidebarMenu/SubMenu';
import { SidebarDataInvestment } from '../SidebarMenu/SidebarDataInvestment';



const CompanyHeader = () => (
  <div className="channel-list__header">
    <p className="channel-list__header__text">App Dev</p>
  </div>
);

export const InvestmentList = (props) => {
  const {
    filters,
    options,
    setCreateType,
    setIsCreating,
    setIsEditing,
    sort,
  } = props;
  return (
    
      <div className="channel-list__list__wrapper">
        <CompanyHeader />
       
     
        {SidebarDataInvestment.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
   

      </div>
    
  );
};
