import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from './components/Auth0/auth0';

ReactDOM.render(
  <React.StrictMode>
      <Auth0Provider
    domain={'blacktreeappdev.eu.auth0.com'}
    client_id={'IkdbaEiwBQrgk0SwrCouFoQQbkcjvpnu'}
    grant_type ={'client_credentials'}
    client_secret=  {'DOxZk9wBsJZUHB0NOhgQywJd90CUD0EhbXCYFnl7PZ0Ub6YzMYLYxH6mXZj63dpe'}
    audience= {'https://blacktreeappdev.eu.auth0.com/api/v2/'}
    redirect_uri={window.location.origin}
    useRefreshTokens

cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>,
  </React.StrictMode>,

  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
