import React, { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { Chat, enTranslations, Streami18n } from "stream-chat-react";
// import { createGlobalStyle } from 'styled-components';
import "stream-chat-react/dist/css/index.css";
import Plaid from "./pages/Plaid";
import Team from "./pages/Team";
import Profile from "./pages/Profile";
import "./App.css";

import { useChecklist } from "./ChecklistTasks";
import { ChannelContainer } from "./components/ChannelContainer/ChannelContainer";
import { ChannelListContainer } from "./components/ChannelListContainer/ChannelListContainer";
// import { ColorSlider } from './components/ColorSlider/ColorSlider';
import axios from "axios";
import { getRandomImage } from "./assets";
import { SideBarFlag, SideBarLogo } from "./assets";
import { BankingListContainer } from "./components/BankingListContainer/BankingListContainer";
import { ChatContainer } from "./components/ChatContainer/ChatContainer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import { SideBarProfile } from "./assets/SideBarProfile";
import { UserProfileContainer } from "./components/UserProfileContainer/UserProfileContainer";
import { useAuth0 } from "./components/Auth0/auth0";
import ChatView from "./components/ChatContainer/Chat";
/* const SideBar = (props) => {
  const { setChatIcon, setBankIcon, setProfileIcon } = props;
  return (
    <div className="channel-list__sidebar">
      <div className="channel-list__sidebar__icon1">
        <div className="icon1__inner">
          <SideBarLogo {...{ setChatIcon, setBankIcon, setProfileIcon }} />
        </div>
      </div>
      <div className="channel-list__sidebar__icon2">
        <div className="icon2__inner">
          <SideBarFlag {...{ setChatIcon, setBankIcon, setProfileIcon }} />
        </div>
      </div>
      <div className="channel-list__sidebar__icon3">
        <div className="icon3__inner">
          <SideBarProfile {...{ setChatIcon, setBankIcon, setProfileIcon }} />
        </div>
      </div>
    </div>
  );
}; */
/* const urlParams = new URLSearchParams(window.location.search);
const apiKey = urlParams.get("apikey") || process.env.REACT_APP_STREAM_KEY;
const user = urlParams.get("user") || process.env.REACT_APP_USER_ID;
const theme = urlParams.get("theme") || "light";
const userToken =
  urlParams.get("user_token") || process.env.REACT_APP_USER_TOKEN;
const targetOrigin =
  urlParams.get("target_origin") || process.env.REACT_APP_TARGET_ORIGIN;
const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
    Flag: "Pin to Channel",
  },
});

// const GlobalColor = createGlobalStyle`
//   body {
//     --primary-color: ${(props) => props.color.current};
//   }
// `;

const filters = [{ type: "team" }, { type: "messaging" }, { type: "apps" }];
const options = { state: true, watch: true, presence: true, limit: 3 };
const sort = { last_message_at: -1, updated_at: -1 };

const client = StreamChat.getInstance(apiKey);
//const chatClient = new StreamChat(apiKey);
//const token1 = chatClient.devToken('dinesh');
//console.log(token1);
//const client = new StreamChat(apiKey, 't8ct45s8xkmxukm7aberxue4kvfs5twb5cmv8jbq8a7h84ektaa76mk7nbfpbzyy');
//const userToken= client.createToken('dinesh', Math.floor(Date.now() / 1000) + (60 * 60));
try {
  client
    .connectUser({ id: user, name: user, image: getRandomImage() }, userToken)
    .catch(Error);
} catch (Error) {
  console.log("Error to connect");
} */
//client.connectUser({ id: 'dinesh', name: 'dinesh', image: getRandomImage() }, token1);
const App = () => {
  const [partnerName, setPartnerName] = useState("");
  const [isPartner, setIsPartner] = useState("");
  const [isFeedPage, setIsFeedPage] = useState("");
  //console.log("targetOrigin" + targetOrigin);

  //useChecklist(client, targetOrigin);

  // const primaryColor = useRef('78, 29, 157');
  const { loading, user, loginWithRedirect } = useAuth0();
  useEffect(() => {
    const username= 'Jayaram';
 /*    async function getToken() {
    try {
      const response = await axios.post('http://localhost:5500/join', {
        username,
      });
      const { token } = response.data;
      console.log('token ::: '+token);
      const apiKey = response.data.api_key;
    }catch (err) {
      console.log(err);
    }
  }  */
    const handleColorChange = (color) => {
      const root = document.documentElement;
      if (color.length && color.length === 7) {
        root.style.setProperty("--primary-color", color);
        root.style.setProperty("--primary-color-alpha", `${color}1A`);
      }
    };
     // getToken();
    window.addEventListener("message", (event) =>
      handleColorChange(event.data)
    );
    return () =>
      window.removeEventListener("message", (event) =>
        handleColorChange(event.data)
      );
  }, []);
  //console.log('AppJS chatIcon' +chatIcon);
  //console.log('AppJS bankIcon' +bankIcon);
  if (loading) {
    return <div>Loading...</div>;
  }
  {
    !user && loginWithRedirect({});
  }
  function SetPartnerPage() {
    let { partnername } = useParams();
    console.log("Partner: " + partnername);
    setPartnerName(partnername);
    if (isPartner =='') {
    setIsPartner("yes")
    }
    return "";
  }
  function SetFeedPage() {
    console.log('in feed func');
    setIsFeedPage('yes');
    return "";
  }
  //console.log("Partner Name: " + partnername);
  return (
    <>
      {user && <ChatView />}
    </>
  );
};

export default App;
