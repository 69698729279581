import React from 'react';
import { ChannelList } from 'stream-chat-react';
import { AddChannel } from '../../assets';
import './UserProfileContainer.css';

import { ChannelSearch } from '../ChannelSearch/ChannelSearch';
import { TeamChannelList } from '../TeamChannelList/TeamChannelList';
import { TeamChannelPreview } from '../TeamChannelPreview/TeamChannelPreview';
import {SidebarDataUserProfile} from '../SidebarMenu/SidebarDataUserProfile';
import SubMenu from '../SidebarMenu/SubMenu';
import { SideBarFlag, SideBarLogo } from '../../assets';
const SideBar = () => (
  <div className="channel-list__sidebar">
    <div className="channel-list__sidebar__icon1">
      <div className="icon1__inner">
        <SideBarLogo />
      </div>
    </div>
    <div className="channel-list__sidebar__icon2">
      <div className="icon2__inner">
        <SideBarFlag />
      </div>
    </div>
  </div>
);

const CompanyHeader = () => (
  <div className="channel-list__header">
    <p className="channel-list__header__text">App Dev</p>
  </div>
);

export const UserProfileContainer = (props) => {
  const {
    filters,
    options,
    setCreateType,
    setIsCreating,
    setIsEditing,
    sort,
  } = props;
  return (
    
      <div className="channel-list__list__wrapper">
        <CompanyHeader />
        {SidebarDataUserProfile.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
      </div>
  );
};
