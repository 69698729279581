import React from 'react';
import Link from '../components/OpenBanking/Link';
import AccessTokenLink from '../components/OpenBanking/AccessTokenLink';

const Plaid = (props) => {
 /*  console.log(window.location.pathname);
  console.log(props.location);
  console.log(props.match);
  console.log(props.location.pathname); */
  var domain = props.location.pathname.split('/');
  var type= domain[domain.length -1];
let locStorage = localStorage.getItem('data');
console.log('locStorage: '+locStorage);
if (locStorage == null) {
  return (
    <div className='home'>
      <h1>Open Banking Plaid</h1>
      <Link itype={type}/>
    </div>
  );
} else if (locStorage != null) {
  return (
    <div className='home'>
      <h1>Access Token Present</h1>
      <AccessTokenLink itype={type}/>
    </div>
  );
}
};

export default Plaid;