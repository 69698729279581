import React, { useContext, useState } from "react";
import { ChatContext } from "stream-chat-react";

import "./CreateChannel.css";

import { UserList } from "./UserList";

import { CloseCreateChannel } from "../../assets";

const ChannelNameInput = ({ channelName = "", setChannelName }) => {
  const handleChange = (event) => {
    event.preventDefault();
    setChannelName(event.target.value);
  };

  return (
    <div className="channel-name-input__wrapper">
      <p>Name</p>
      <input
        onChange={handleChange}
        placeholder="channel-name"
        type="text"
        value={channelName}
      />
      <p>Add Members</p>
    </div>
  );
};

export const CreateChannel = ({ createType, filters, setIsCreating,partnerName,setIsPartner,isPartner,isCreating }) => {
  const { client, setActiveChannel } = useContext(ChatContext);

  const [channelName, setChannelName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([client.userID]);

  const channelCreate = async (event) => {
    event.preventDefault();
    console.log(
      "selectedUsers: " + selectedUsers + "channelName " + channelName
    );
    const newChannel = await client.channel(createType, channelName, {
      name: channelName,
      members: [client.userID,partnerName],
    });

    await newChannel.watch();

    setChannelName("");
    setIsCreating(false);
    setSelectedUsers([client.userID]);
    setActiveChannel(newChannel);
    setIsPartner("ny");
  };
  const createChannel = async (event) => {
    event.preventDefault();
    console.log(
      "selectedUsers: " + selectedUsers + "channelName " + channelName
    );
    const newChannel = await client.channel(createType, channelName, {
      name: channelName,
      members: selectedUsers,
    });

    await newChannel.watch();

    setChannelName("");
    setIsCreating(false);
    setSelectedUsers([client.userID]);
    setActiveChannel(newChannel);
  };
  console.log("createType: " + createType);
  if(partnerName!="" && createType == "messaging" && isPartner!='ny') {
console.log('in createchannel partner if');
return (
  <div className="create-channel__button-wrapper" onClick={channelCreate}>
          <p>
            {createType === "team" ? "Create Channel" : `Please click on this button to chat with ${partnerName}`}
          </p>
        </div>
);
  } else if (createType != "apps") {
    console.log("inside create channel message if " );
    return (
      <div className="create-channel__container">
        <div className="create-channel__header">
          <p>
            {createType === "team"
              ? "Create a New Channel"
              : "Send a Direct Message"}
          </p>
          <CloseCreateChannel {...{ setIsCreating }} />
        </div>
        {createType === "team" && (
          <ChannelNameInput {...{ channelName, setChannelName }} />
        )}
        <UserList {...{ filters, setSelectedUsers }} />
        <div className="create-channel__button-wrapper" onClick={createChannel}>
          <p>
            {createType === "team" ? "Create Channel" : "Create Message Group"}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="create-channel__container">
        <div className="create-channel__header">
          <p>
            {createType === "apps"
              ? "Banking Transactions"
              : "Send a Direct Message"}
          </p>
          <br></br>
        </div>
        <span className="spanbreak">
          The easiest way for users to connect to their bank
        </span>
        <div
          className="create-channel__button-wrapper alignclass"
          onClick={createChannel}
        >
          <p>
            {createType === "apps"
              ? "Direct to User Transactions"
              : "Create Message Group"}
          </p>
        </div>
      </div>
    );
  }
};
