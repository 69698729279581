import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarDataUserProfile = [
  {
    title: 'My Profile',
   
    icon: <AiIcons.AiFillProfile />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Profile',
        path: '/myprofile/profile',
        icon: <IoIcons.IoIosPerson />
      }/* ,
      {
        title: 'Revenue',
        path: '/overview/revenue',
        icon: <IoIcons.IoIosPaper />
      } */
    ]
  },
  /* {
    title: 'Team',
    path: '/team',
    icon: <IoIcons.IoMdPeople />
  } *//* ,
  {
    title: 'Messages',
    path: '/messages',
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Message 1',
        path: '/messages/message1',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Message 2',
        path: '/messages/message2',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  } */,
  {
    title: 'LogOut',
    path: '/logout',
    icon: <IoIcons.IoIosLogOut />
  }
];